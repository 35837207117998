import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function Menor18anosPage(){
    return (
        <Container>
            <Title>Menor de 18 anos</Title>

            <Paragraph>
                Criança e adolescente menor de 18 anos acionar o conselho tutelar e/ou vara da infância e juventude (Art 13 do ECA)
            </Paragraph>

            <Paragraph>
                Criança e adolescente acionar o conselho tutelar (Obrigatório)
            </Paragraph>

            <Paragraph>
                Acolhimento, anamnese e escuta qualificada (médico e/ou enfermeiro(a))
            </Paragraph>

            <Paragraph>
                Garantir privacidade e local acolhedor
            </Paragraph>

            <Paragraph>
                NOTIFICAÇÃO COMPULSÓRIA
            </Paragraph>
            
        </Container>
    )
}