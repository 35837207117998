import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

import { useHistory } from 'react-router-dom'
import Button from '../../Components/Button'

export default function ViolenciaCronicaPage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Violência Sexual Crônica</Title>

            <Paragraph>
                Acionar o serviço de assistência social
            </Paragraph>

            <Paragraph>
                Orientar sobre o direito de denúncia (Não obrigatório)
            </Paragraph>

            <Button onClick={() => {
                history.push("clinicoLaboratorial")
            }}>Próximo</Button>
        </Container>
    )
}