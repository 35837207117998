import React from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'


import HomePage from '../../Pages/HomePage'
import InitialPage from '../../Pages/InitialPage'
import MainMenuPage from '../../Pages/MainMenuPage'
import CurriculoPage from '../../Pages/CurriculoPage'

import HivPage from '../../Pages/HivPage'
import HivMedicamentosPage from '../../Pages/HivPage/HivMedicamentosPage'
import HivInformacoesPage from '../../Pages/HivPage/HivInformacoesPage'
import HivProfilaxiaPage from '../../Pages/HivPage/HivProfilaxiaPage'
import HivSeguimentoLabPage from '../../Pages/HivPage/HivSeguimentoLabPage'

import SifilisPage from '../../Pages/SifilisPage'
import SifilisInformacoesPage from '../../Pages/SifilisPage/SifilisInformacoesPage'
import SifilisMedicamentosPage from '../../Pages/SifilisPage/SifilisMedicamentosPage'
import SifilisPepPage from '../../Pages/SifilisPage/SifilisPepPage'
import SifilisSeguimentoLabPage from '../../Pages/SifilisPage/SifilisSeguimentoLabPage'

import HepatitePage from '../../Pages/HepatitePage'
import HepatiteInformacoesPage from '../../Pages/HepatitePage/HepatiteInformacoesPage'
import HepatitePepPage from '../../Pages/HepatitePage/HepatitePepPage'
import HepatiteSeguimentoLabPage from '../../Pages/HepatitePage/HepatiteSeguimentoLabPage'

import InfeccoesGenitaisPage from '../../Pages/InfeccoesGenitaisPage'
import InfeccoesGenitaisInformacoesPage from '../../Pages/InfeccoesGenitaisPage/InfeccoesGenitaisInformacoesPage'
import InfeccoesGenitaisMedicamentosAdultoPage from '../../Pages/InfeccoesGenitaisPage/InfeccoesGenitaisMedicamentosAdultoPage'
import InfeccoesGenitaisPepPage from '../../Pages/InfeccoesGenitaisPage/InfeccoesGenitaisPepPage'
import InfeccoesGenitaisSeguimentoLabPage from '../../Pages/InfeccoesGenitaisPage/InfeccoesGenitaisSeguimentoLabPage'

import ContracepcaoEmergencialPage from '../../Pages/ContracepcaoEmergencial'
import ContracepcaoEmergencialInformacoesPage from '../../Pages/ContracepcaoEmergencial/ContracepcaoEmergencialInformacoesPage'
import ContracepcaoEmergencialPrimeiraPage from '../../Pages/ContracepcaoEmergencial/ContracepcaoEmergencialPrimeiraPage'
import ContracepcaoEmergencialSegundaPage from '../../Pages/ContracepcaoEmergencial/ContracepcaoEmergencialSegundaPage'
import ContracepcaoEmergencialContraPage from '../../Pages/ContracepcaoEmergencial/ContracepcaoEmergencialContraPage'

import AbortoLegalPage from '../../Pages/AbortoLegalPage'
import AbortoLegalInformacoesPage from '../../Pages/AbortoLegalPage/AbortoLegalInformacoesPage'
import AbortoLegal12Page from '../../Pages/AbortoLegalPage/AbortoLegal12Page'
import AbortoLegal1320Page from '../../Pages/AbortoLegalPage/AbortoLegal1320Page'
import AbortoLegal20Page from '../../Pages/AbortoLegalPage/AbortoLegal20Page'

import AlgoritmoGeralPage from '../../Pages/AlgoritmoGeralPage'
import Menor18AnosPage from '../../Pages/AlgoritmoGeralPage/Menor18AnosPage'
import Maior18AnosPage from '../../Pages/AlgoritmoGeralPage/Maior18AnosPage'
import ProcedimentosPage from '../../Pages/AlgoritmoGeralPage/ProcedimentosPage'
import RiscosPage from '../../Pages/AlgoritmoGeralPage/RiscosPage'
import GravidezPage from '../../Pages/AlgoritmoGeralPage/GravidezPage'
import ViolenciaCronicaPage from '../../Pages/AlgoritmoGeralPage/ViolenciaCronicaPage'
import ViolenciaRecentePage from '../../Pages/AlgoritmoGeralPage/ViolenciaRecentePage'
import ClinicoLaboratorialPage from '../../Pages/AlgoritmoGeralPage/ClinicoLaboratorialPage'
import AltaHospitalar from '../../Pages/AlgoritmoGeralPage/AltaHospitalarPage'

import './style.css'

import ContainerPage from '../ContainerPage'

function App() {
  return (
    <Router>
      <ContainerPage>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>

          <Route path="/initial" exact>
            <InitialPage />
          </Route>

          <Route path="/mainMenu" exact>
            <MainMenuPage />
          </Route>

          <Route path="/curriculo" exact>
            <CurriculoPage />
          </Route>

          {/* 
            Rotas HIV - Início
          */}

          <Route path="/hiv" exact>
            <HivPage />
          </Route>

          <Route path="/hiv/informacoes" exact>
            <HivInformacoesPage />
          </Route>

          <Route path="/hiv/medicamentos" exact>
            <HivMedicamentosPage />
          </Route>

          <Route path="/hiv/profilaxiaPosExposicao" exact>
            <HivProfilaxiaPage />
          </Route>

          <Route path="/hiv/seguimentoLaboratorial" exact>
            <HivSeguimentoLabPage />
          </Route>

          {/* 
            Rotas HIV - Fim
          */}

          {/* 
            Rotas Sifilis - Início
          */}

          <Route path="/sifilis" exact>
            <SifilisPage />
          </Route>
          <Route path="/sifilis/informacoes" exact>
            <SifilisInformacoesPage />
          </Route>

          <Route path="/sifilis/pep" exact>
            <SifilisPepPage />
          </Route>

          <Route path="/sifilis/medicamentos" exact>
            <SifilisMedicamentosPage />
          </Route>

          <Route path="/sifilis/seguimentoLaboratorial" exact>
            <SifilisSeguimentoLabPage />
          </Route>

          {/* 
            Rotas Sifilis - Fim
          */}

          {/* 
            Rotas Hepatite B - Início
          */}

          <Route path="/hepatite" exact>
            <HepatitePage />
          </Route>
          <Route path="/hepatite/informacoes" exact>
            <HepatiteInformacoesPage />
          </Route>

          <Route path="/hepatite/pep" exact>
            <HepatitePepPage />
          </Route>

          <Route path="/hepatite/seguimentoLaboratorial" exact>
            <HepatiteSeguimentoLabPage />
          </Route>

          {/* 
            Rotas Hepatite B - Fim
          */}

          {/* 
            Rotas Infecções Genitais - Início
          */}

          <Route path="/infeccoesGenitais" exact>
            <InfeccoesGenitaisPage />
          </Route>
          <Route path="/infeccoesGenitais/informacoes" exact>
            <InfeccoesGenitaisInformacoesPage />
          </Route>

          <Route path="/infeccoesGenitais/pep" exact>
            <InfeccoesGenitaisPepPage />
          </Route>

          <Route path="/infeccoesGenitais/medicamentosAdulto" exact>
            <InfeccoesGenitaisMedicamentosAdultoPage />
          </Route>

          <Route path="/infeccoesGenitais/seguimentoLaboratorial" exact>
            <InfeccoesGenitaisSeguimentoLabPage />
          </Route>

          {/* 
            Rotas Infecções Genitais - Fim
          */}

          {/* 
            Rotas Contracepção Emergencial - Início
          */}

          <Route path="/contracepcaoEmergencial" exact>
            <ContracepcaoEmergencialPage />
          </Route>
          <Route path="/contracepcaoEmergencial/informacoes" exact>
            <ContracepcaoEmergencialInformacoesPage />
          </Route>

          <Route path="/contracepcaoEmergencial/primeiraEscolha" exact>
            <ContracepcaoEmergencialPrimeiraPage />
          </Route>

          <Route path="/contracepcaoEmergencial/segundaEscolha" exact>
            <ContracepcaoEmergencialSegundaPage />
          </Route>

          <Route path="/contracepcaoEmergencial/contraIndicacao" exact>
            <ContracepcaoEmergencialContraPage />
          </Route>

          {/* 
            Rotas Contracepção Emergencial - Fim
          */}

          {/* 
            Rotas Aborto Legal - Início
          */}

          <Route path="/abortoLegal" exact>
            <AbortoLegalPage />
          </Route>

          <Route path="/abortoLegal/informacoes" exact>
            <AbortoLegalInformacoesPage />
          </Route>

          <Route path="/abortoLegal/ate12" exact>
            <AbortoLegal12Page />
          </Route>

          <Route path="/abortoLegal/13a20" exact>
            <AbortoLegal1320Page />
          </Route>

          <Route path="/abortoLegal/mais20" exact>
            <AbortoLegal20Page />
          </Route>

          {/* 
            Rotas Aborto Legal - Fim
          */}

          {/* 
            Rotas Algoritmo Geral - Início
          */}

          <Route path="/algoritmoGeral" exact>
            <AlgoritmoGeralPage />
          </Route>

          <Route path="/algoritmoGeral/menor18anos" exact>
            <Menor18AnosPage />
          </Route>

          <Route path="/algoritmoGeral/maior18anos" exact>
            <Maior18AnosPage />
          </Route>

          <Route path="/algoritmoGeral/procedimentos" exact>
            <ProcedimentosPage />
          </Route>

          <Route path="/algoritmoGeral/riscos" exact>
            <RiscosPage />
          </Route>

          <Route path="/algoritmoGeral/violenciaCronica" exact>
            <ViolenciaCronicaPage />
          </Route>

          <Route path="/algoritmoGeral/violenciaRecente" exact>
            <ViolenciaRecentePage />
          </Route>

          <Route path="/algoritmoGeral/gravidez" exact>
            <GravidezPage />
          </Route>

          <Route path="/algoritmoGeral/altaHospitalar" exact>
            <AltaHospitalar />
          </Route>

          <Route path="/algoritmoGeral/clinicoLaboratorial" exact>
            <ClinicoLaboratorialPage />
          </Route>

          {/* 
            Rotas Algoritmo Geral - Fim
          */}
        </Switch>
      </ContainerPage>
    </Router>
  )
}

export default App
