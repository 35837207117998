import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function ContracepcaoEmergencialInformacoesPage(){
    return (
        <Container>
            <Title>Informações</Title>

            <Paragraph>
                Se realizada em até 72 horas após a agressão tem 97% de eficácia, em até 120 horas tem entre 50 e 70% e não adianta realizar após 8 dias do abuso sexual, pois já houve a nidação 
            </Paragraph>

            <Paragraph>
                Preferencialmente a contracepção de emergência deve ser realizada por via oral, mas na impossibilidade dessa, usa-se via vaginal
            </Paragraph>
        </Container>
    )
}