import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function HivMedicamentosPag(){
    return (
        <Container>
            <Title>
                Medicamentos 
            </Title>

            <Paragraph>
                Usados em conjunto por via oral durante 28 dias.
            </Paragraph>

            <Paragraph>
                Tenofovir 300 mg 1 comprimido ao dia
            </Paragraph>
            <Paragraph>
                Lamivudina 300 mg 1 comprimido ao dia
            </Paragraph>
            <Paragraph>
                Dolutegravir 50 mg 1 comprimido ao dia
            </Paragraph>
        </Container>
    )
}