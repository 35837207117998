import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function AbortoLegal12Page(){
    return (
        <Container>
            <Title>Até 12 semanas</Title>

            <SubTitle>Primeira escolha</SubTitle>
            <Paragraph>
                Aspiração manual intra-uterina (AMIU)
            </Paragraph>

            <SubTitle>Segunda escolha</SubTitle>
            <Paragraph>
                Misoprostol 800 mcg via vaginal a cada 24 horas
            </Paragraph>

            <SubTitle>Terceira escolha</SubTitle>
            <Paragraph>
                Curetagem uterina
            </Paragraph>
        </Container>
    )
}