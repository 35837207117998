import React from 'react'

import { Container } from './styles'

import Title from '../../Components/Title'
import Button from '../../Components/Button'
import { useHistory } from 'react-router-dom'

export default function MainMenuPage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Escolha uma opção</Title>
            <Button onClick={() => {
                history.push("hiv")
            }}>HIV</Button>

            <Button onClick={() => {
                history.push("sifilis")
            }}>Sífilis</Button>

            <Button onClick={() => {
                history.push("hepatite")
            }}>Hepatite B</Button>

            <Button onClick={() => {
                history.push("infeccoesGenitais")
            }}>Infecções Genitais</Button>

            <Button onClick={() => {
                history.push("contracepcaoEmergencial")
            }}>Contracepção Emergencial</Button>

            <Button onClick={() => {
                history.push("abortoLegal")
            }}>Aborto Legal</Button>

            <Button onClick={() => {
                history.push("algoritmoGeral")
            }}>Informações Gerais</Button>
        </Container>
    )
}