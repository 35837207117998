import React from 'react'

import { Container, Image, Text } from './styles'

import Button from '../../Components/Button'

import geraldo from '../../assets/geraldo.jpg'
import lidinei from '../../assets/lidinei.jpg'
import lilyana from '../../assets/lilyana.jpg'
import { useHistory } from 'react-router-dom'


export default function CurriculoPage(){
    const history = useHistory()
    return (
        <Container>
            <Text>Prof. Dr. Geraldo Magela Salomé</Text>
            <Image className="img-fluid" src={geraldo} alt="Universidade do vale do sapucaí" />
            <Text>
                Possui Graduação em Enfermagem e Obstetrícia pela Faculdade de Enfermagem e Obstetrícia de Passos.
                Professor Adjundo do Curso de Mestrado profissional Ciências Aplicadas à Saúde da Universidade do Vale do
                Sapucaí ?Conceito CAPES 4?. Mestre em Ciências da Saúde pelo Programa de Pós Graduação de Enfermagem da
                Universidade Federal de São Paulo ?Conceito CAPES 5?. Doutor em Ciências e Pós- Doutorado pelo Programa de
                Pós-Graduação em Cirurgia Plástica da Universidade Federal de São Paulo. ?Conceito CAPES 6?. Pós doutorado
                pelo Programa de Pós-Graduação em Cirurgia Plástica da Universidade Federal de São Paulo. ?Conceito CAPES
                6?. No momento cursando o Pós Doutorado com Bolsa da CAPES, pelo Programa de Pós-Graduação em Cirurgia
                Plástica da Universidade Federal de São Paulo. ?Conceito CAPES 6?. Especialista em Enfermagem em Unidade de
                Terapia Intensiva pelo Centro Universitário São Camilo, Especialização em Estomaterapia pela Universidade de
                Taubaté, Especialização em Segurança do paciente e qualidade em serviço de saúde pela Faculdade Unyleya e
                Especialização em Gerontologia pela Faculdade Unyleya, Especialista em enfermagem dermatológica pela
                Associação Brasileira de enfermagem em dermatologia . Tem experiência na área de Enfermagem, com ênfase
                em Unidade de Terapia Intensiva, Pronto Socorro e prevenção e tratamento de feridas, estomas. Diretor do
                Conselho Fiscal da Associação Brasileira em Estomaterapia Seção Minas Gerais ?Gestão 2012-2014?, Presidente
                da Associação Brasileira em Estomaterapia da Seção Minas Gerais ?Gestão 2014-2015 e Membro do conselho
                fiscal da Associação Brasileira em Estomaterapia (Gestão 2010 A 2014). 12 Programa de computador com
                registro no Instituto Nacional da Propriedade Industrial, um deposito de patente &quot;Pó composto da casca da
                banana verde,BR:10-2020-01-3887-1. Duas Marca registrada no Instituto Nacional da Propriedade Industrial.
                2017-Auxílio à Pesquisa da Fundação de Amparo á Pesquisa do Estado de Minas Gerais ? FAPEMIG, modalidade
                ?Edital 001/2017 - demanda universal? Processo N.: CDS - APQ-00235-17. Apoio á pesquisa R$ 19,317,38..
                Projeto: ?Construção e validação de um algoritmo e desenvolvimento de aplicativo para auxiliar na prevenção de
                lesões cutâneas direcionado à segurança do paciente validado até 2019.Em 19 de setembro foi outorgado até
                2021.
            </Text>
            <Text>Lidinei José Alves</Text>
            <Image className="img-fluid" src={lidinei} alt="Universidade do vale do sapucaí" />
            <Text>
                Médico Ginecologista e Obstetra graduado pela Faculdade de Medicina de Itajubá (FMIt) em 2008. Residência
                médica pelo Hospital Escola da Faculdade de Medicina de Itajubá em Ginecologia e Obstetrícia em 2012. Pós-
                graduado em ultrassonografia pela Escola de Ultrassonografia de Ribeirão Preto (EURP) em 2012. Possui
                experiência profissional em atendimento ambulatorial e de emergência em clínica médica, ginecologia e
                obstetrícia e realização de exames de imagem em ultrassonografia na rede pública e particular. Exerce atuação
                profissional como médico ginecologista e obstetra na Prefeitura Municipal de Natércia-MG (Cargo público
                conseguido sob concurso público desde 2012), Prefeitura Municipal de Heliodora (Prestador de serviço desde
                2013), Plantonista da Maternidade do Hospital de Clínicas de Itajubá (Prestador de serviço desde 2012) e atua
                como professor adjunto da disciplina de Ginecologia e Obstetrícia da Faculdade de Medicina de Itajubá desde
                2019. Atualmente é mestrando do Programa de Metrado Profissional em Ciências Aplicadas à Saúde da
                Universidade Vale do Sapucaí (UNIVÁS).
            </Text>
            <Text>Profa. Dra. Lyliana Coutinho Resende Barbosa</Text>
            <Image className="img-fluid" src={lilyana} alt="Universidade do vale do sapucaí" />
            <Text>
                Possui graduação em Medicina pela Universidade do Vale do Sapucaí - UNIVÁS (1993) e
                doutorado em Medicina (Ginecologia) pela Universidade Federal de São Paulo - UNIFESP
                (2010). Atualmente é médica plantonista gineco-obstetrícia do Hospital das Clínicas Samuel
                Libânio, professora auxiliar da Faculdade de Ciências da Saúde, professora permanente do
                Mestrado Profissional em Ciências Aplicadas à Saúde (CAPES) da Universidade do Vale do
                Sapucaí, preceptora da residência Médica em Ginecologia e Obstetrícia do Hospital das Clínicas
                Samuel Libânio, responsáve técnical pelo Ambulatório de Patologia do Trato Genital Inferior,
                Colposcopia e Oncoginecologia do Hospital das Clínicas Samuel Libânio, atuando principalmente
                nos seguintes temas: telomerase, cancer do colo uterino, hpv, proteínas inibidoras da apoptose
                e survivina, qualidade de vida, p16 e Ki67.
            </Text>
            

            <Button onClick={() => {
                history.push("initial")
            }}>Início</Button>
        </Container>
    )
}