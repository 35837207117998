import React from 'react'

import { Container, Image, Text } from './styles'

import Button from '../../Components/Button'

import mpcas from '../../assets/mpcas.png'
import univas from '../../assets/univas.png'
import { useHistory } from 'react-router-dom'


export default function HomePage(){
    const history = useHistory()
    return (
        <Container>
            <Text>Lidinei José Alves</Text>
            <Text>Profa. Dra. Lyliana Coutinho Resende Barbosa</Text>
            <Text>Prof. Dr. Geraldo Magela Salomé</Text>
            <Image className="img-fluid" src={univas} alt="Universidade do vale do sapucaí" />
            <br />
            <Image className="img-fluid" src={mpcas} alt="Mestrado Profissional em Ciências Aplicadas à Saúde" />

            <Button onClick={() => {
                history.push("initial")
            }}>Início</Button>

            <Button onClick={() => {
                history.push("curriculo")
            }}>Currículo dos autores</Button>
        </Container>
    )
}