import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function InfeccoesGenitaisPepPage(){
    return (
        <Container>
            <Title>Profilaxia Pós-Exposição</Title>

            <Paragraph>
                Realizada após qualquer tipo de violência sexual com penetração vaginal ou anal desprotegida (PEP recomendada)
            </Paragraph>
        </Container>
    )
}