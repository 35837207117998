import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function SifilisPepPage(){
    return (
        <Container>
            <Title>Profilaxia Pós-Exposição</Title>

            <Paragraph>
                Realizada após qualquer tipo de violência sexual
            </Paragraph>

            <SubTitle>PEP recomendada</SubTitle>

            <Paragraph>
                Violência sexual com penetração vaginal ou anal desprotegida
            </Paragraph>
        </Container>
    )
}