import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

import { useHistory } from 'react-router-dom'
import Button from '../../Components/Button'

export default function ViolenciaRecentePage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Violência Sexual Recente</Title>

            <Paragraph>
                Avaliar estado geral, vias aéreas e nível de consciência e se necessário encaminhar para o serviço de emergência
            </Paragraph>

            <Paragraph>
                Anticoncepção de emergência, Profilaxia pós-exposição (PEP) e realização de conduta conforme algorítmo específico. Avaliar estado vacinal contra tétano
            </Paragraph>

            <Button onClick={() => {
                history.push("clinicoLaboratorial")
            }}>Próximo</Button>
        </Container>
    )
}