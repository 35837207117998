import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function InfeccoesGenitaisMedicamentosAdultoPage(){
    return (
        <Container>
             <Title>Pacientes com menos de 45kg de peso corporal</Title>

            <Paragraph>
                Medicamentos profiláticos para crianças e pacientes com menos de 45 Kg de peso corporal
            </Paragraph>

            <SubTitle>Clamidiose</SubTitle>

            <Paragraph>
                Azitromicina 20mg/kg VO em dose única, não ultrapassar 1g (apresentação 600mg/15ml ou 900mg/22,5ml)
            </Paragraph>

            <SubTitle>Cancro-mole</SubTitle>

            <Paragraph>
                Ceftriaxona 125mg 1 ml IM dose única (apresentação de 250mg com 2 ml de diluente)
            </Paragraph>

            <SubTitle>Gonorréia</SubTitle>

            <Paragraph>
                Ciprofloxacino 50mg/ml VO em dose única, não ultrapassar 500mg (Contra-indicação: gestantes)
            </Paragraph>

            <SubTitle>Tricomoníase e Vaginose bacteriana</SubTitle>

            <Paragraph>
                Metronidazol 40mg/ml VO em dose única, não ultrapassar 2g
            </Paragraph>

            <Title>Pacientes com mais de 45kg de peso corporal</Title>

            <Paragraph>
                Medicamentos profiláticos para pacientes com mais de 45kg de peso corporal
            </Paragraph>

            <SubTitle>Clamidiose</SubTitle>

            <Paragraph>
                Azitromicina 2 comprimidos de 500mg VO em dose única (Total de 1g)
            </Paragraph>

            <SubTitle>Cancro-mole</SubTitle>
            
            <Paragraph>
                Ceftriaxona 250 ou 500mg 1 ampola IM em dose única
            </Paragraph>

            <SubTitle>Gonorréia</SubTitle>

            <Paragraph>
                Ofloxacino 400mg ou Ciprofloxacino 500mg VO em dose única (Contra-indicação: gestantes)
            </Paragraph>

            <SubTitle>
                Tricomoníase e Vaginose bacteriana
            </SubTitle>

            <Paragraph>
                Metronidazol 2g VO em dose única (apresentação de comprimidos de 250mg ou 400mg)
            </Paragraph>
        </Container>
    )
}