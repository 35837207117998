import React from 'react'

import { Container, Paragraph, TextBold } from './styles'

import Title from '../../Components/Title'

export default function SifilisMedicamentosPage(){
    return (
        <Container>
            <Title>Medicamentos Profiláticos</Title>

            <Paragraph>
                <TextBold>Penicilina G Benzatina 1200000UI:</TextBold> aplicar 2 ampolas IM, sendo 1 em cada nádega em dose única para adultos e adolescentes com mais de 45 kg de peso corporal
            </Paragraph>

            <Paragraph>
                <TextBold>Penicilia G Benzatina 50000UI/kg:</TextBold> de peso IM em dose única para crianças e adoslescentes com menos de 45 kg de peso corporal
            </Paragraph>

            <Paragraph>
                <TextBold>Opção para alérgicos à penicilina:</TextBold> Estearato de Eritromicina 500 mg VO 1 comprimido a cada 6 horas por 7 dias em adultos e 50mg/kg/dia VO no mesmo esquema para crianças e adolescentes
            </Paragraph>

            <Paragraph>
                Obs.: O mesmo esquema terapêutico pode ser usado em gestantes, respeitando o peso corporal e a evidência de alergia à penicilina.
            </Paragraph>
        </Container>
    )
}