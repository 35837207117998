import React from 'react'
import { useHistory } from 'react-router-dom'

import { Container } from './styles'

import Title from '../../Components/Title'
import Button from '../../Components/Button'

export default function HivPage(){
    const history = useHistory()
    return (
        <Container>
            <Title>HIV</Title>

            <Button onClick={() => {
                history.push("hiv/informacoes")
            }}>Informações</Button>
            <Button onClick={() => {
                history.push("hiv/profilaxiaPosExposicao")
            }}>Profilaxia Pós-Exposição</Button>
            <Button onClick={() => {
                history.push("hiv/medicamentos")
            }}>Medicamentos</Button>
            <Button onClick={() => {
                history.push("hiv/seguimentoLaboratorial")
            }}>Seguimento laboratorial</Button>
        </Container>
    )
}