import React from 'react'

import { Container } from './styles'

import Title from '../../Components/Title'
import Button from '../../Components/Button'

import { useHistory } from 'react-router-dom'

export default function AlgoritmoGeralPage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Vítima de Violência Sexual</Title>


            <Button onClick={() => {
                history.push("algoritmoGeral/menor18anos")
            }}>Menor de 18 anos</Button>

            <Button onClick={() => {
                history.push("algoritmoGeral/maior18anos")
            }}>Maior de 18 anos</Button>

            <Button onClick={() => {
                history.push("algoritmoGeral/procedimentos")
            }}>Procedimentos</Button>
        </Container>
    )
}