import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function HepatiteSeguimentoLabPage(){
    return (
        <Container>
            <Title>Seguimento Laboratorial</Title>

            <Paragraph>
                Solicitar HBsAg na admissão, com 3 e 6 meses
            </Paragraph>

            <Paragraph>
                Recomenda-se solicitar anti-HCV no mesmo tempo.
            </Paragraph>
        </Container>
    )
}