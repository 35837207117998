import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function HivProfilaxiaPage(){
    return (
        <Container>
            <Title>Profilaxia Pós-Exposição (PEP)</Title>

            <Paragraph>
                Medida de prevenção à infecção pelo HIV que consiste no uso de medicações anti-retrovirais em até 72 horas após qualquer situação que exista risco de contato com o vírus
            </Paragraph>

            <SubTitle>PEP recomendada</SubTitle>

            <Paragraph>
                Violência sexual com penetração vaginal e/ou anal desprotegida, com ejaculação sofrida há menos de 72 horas
            </Paragraph>

            <SubTitle>PEP alternativa</SubTitle>

            <Paragraph>
                Penetração oral com ejaculação
            </Paragraph>

            

            <SubTitle>PEP não recomenadada</SubTitle>

            <Paragraph>
                Penetração oral sem ejaculação, uso de preservativo durante todo o contato sexual, agressor sabidamente HIV negativo, violência sofrida há mais de 72 horas e abuso sexual crônico pelo mesmo agressor
            </Paragraph>
        </Container>
    )
}