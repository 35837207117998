import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function HivInformacoesPage(){
    return (
        <Container>
            <Title>Informações</Title>

            <SubTitle>Agente causador</SubTitle>

            <Paragraph>
                Vírus da Imunodeficiência Humana
            </Paragraph>
            
            <SubTitle>Transmissão</SubTitle>

            <Paragraph>
                Relação sexual desprotegida, compartilhamento de seringas contaminadas, contato com sangue contaminado e transmissão vertical
            </Paragraph>

            <SubTitle>Diagnóstico</SubTitle>
            
            <Paragraph>
                Teste rápido (30 minutos) e Exames laboratoriais
            </Paragraph>
        </Container>
    )
}