import React from 'react'

import { Container, Image } from './styles'

import pravis from '../../assets/pravis_vertical.png'

export default function Header(){
    return (
        <Container>
            <Image className="img-fluid" src={pravis} alt="PravisAPP" />
        </Container>
    )
}