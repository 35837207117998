import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function ContracepcaoEmergencialContraPage(){
    return (
        <Container>
            <Title>Contra Indicação</Title>

            <Paragraph>
                Em mulheres que sofreram violência sexual e já apresentavam atraso mesntrual (Gravidez suspeitada), o ideal é realizar investigação laboratorial (Solicitar beta-HCG), porém na impossibilidade de realização desta, a contracepção de emergência não está contra-indicada, devendo-se optar sempre pela primeira escolha (Levonorgestrel), pois tem maior eficácia
            </Paragraph>
        </Container>
    )
}