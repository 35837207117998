import React from 'react'

import { Container, Paragraph, TextItalic } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function SifilisInformacoesPage(){
    return (
        <Container>
            <Title>Informações</Title>
            
            <SubTitle>Agente causador</SubTitle>

            <Paragraph>
                <TextItalic>Treponema pallidum</TextItalic>
            </Paragraph>

            <SubTitle>Transmissão</SubTitle>

            <Paragraph>
                Relação sexual desprotegida e transmissão vertical
            </Paragraph>

            <SubTitle>Diagnóstico</SubTitle>

            <Paragraph>
                Teste rápido (30 minutos) e Exames laboratoriais (Testes não treponêmicos)
            </Paragraph>
        </Container>
    )
}