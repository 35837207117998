import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

import { useHistory } from 'react-router-dom'
import Button from '../../Components/Button'

export default function GravidezPage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Gravidez decorrente da violência</Title>

            <Paragraph>
                Orientação sobre o aborto legal
            </Paragraph>

            <Button onClick={() => {
                history.push("altaHospitalar")
            }}>Interrupção da gravidez</Button>

            <Button onClick={() => {
                history.push("altaHospitalar")
            }}>Pré-natal de alto risco</Button>
        </Container>
    )
}