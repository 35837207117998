import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function ContracepcaoEmergencialSegundaPage(){
    return (
        <Container>
            <Title>Segunda Escolha</Title>

            <SubTitle>Método de Yuzpe</SubTitle>

            <Paragraph>
                Usa-se pílula anticoncepcional oral comum (etinilestradiol e progestágeno sintéticos) na dose total de 200mg de etinilestradiol e 1mg do progestágeno, divididas em duas doses iguais, a cada 12 horas, ou administradas em dose única
            </Paragraph>

            <Paragraph>
                Método menos eficaz, pois gera muitos efeitos colaterais (náusea e vômito, principalmente), devendo ser usado somente na falta do medicamento de primeira escolha (Levonorgestrel)
            </Paragraph>
        </Container>
    )
}