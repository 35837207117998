import React from 'react'
import { useHistory } from 'react-router-dom'

import { Container } from './styles'

import Title from '../../Components/Title'
import Button from '../../Components/Button'

export default function SifilisPage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Sífilis</Title>

            <Button onClick={() => {
                history.push("sifilis/informacoes")
            }}>Informações</Button>
            <Button onClick={() => {
                history.push("sifilis/pep")
            }}>Profilaxia Pós-Exposição</Button>
            <Button onClick={() => {
                history.push("sifilis/medicamentos")
            }}>Medicamentos</Button>
            <Button onClick={() => {
                history.push("sifilis/seguimentoLaboratorial")
            }}>Seguimento Laboratorial</Button>
        </Container>
    )
}