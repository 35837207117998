import React from 'react'

import { Container } from './styles'

export default function Title({ children }){
    return (
        <Container>
            { children }
        </Container>
    )
}