import React from 'react'
import { useHistory } from 'react-router-dom'

import { Container } from './styles'

import Title from '../../Components/Title'
import Button from '../../Components/Button'

export default function AbortoLegalPage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Aborto Legal</Title>

            <Button onClick={() => {
                history.push("abortoLegal/informacoes")
            }}>Informações</Button>
            <Button onClick={() => {
                history.push("abortoLegal/ate12")
            }}>Até 12 semanas</Button>
            <Button onClick={() => {
                history.push("abortoLegal/13a20")
            }}>13 a 20 semanas</Button>
            <Button onClick={() => {
                history.push("abortoLegal/mais20")
            }}>mais de 20 semanas</Button>
        </Container>
    )
}