import React from 'react'
import { useHistory } from 'react-router-dom'

import { Container } from './styles'

import Title from '../../Components/Title'
import Button from '../../Components/Button'

export default function HepatitePage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Hepatite B</Title>

            <Button onClick={() => {
                history.push("hepatite/informacoes")
            }}>Informações</Button>

            <Button onClick={() => {
                history.push("hepatite/pep")
            }}>Profilaxia Pós-Exposição</Button>
            
            <Button onClick={() => {
                history.push("hepatite/seguimentoLaboratorial")
            }}>Seguimento Laboratorial</Button>
        </Container>
    )
}