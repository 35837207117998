import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function InfeccoesGenitaisSeguimentoLabPage(){
    return (
        <Container>
            <Title>Seguimento Laboratorial</Title>

            <Paragraph>
                Teste rápido e exame a fresco do conteúdo vaginal feitos na admissão e após 6 semanas
            </Paragraph>
        </Container>
    )
}