import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function Maior18anosPage(){
    return (
        <Container>
            <Title>Maior de 18 anos</Title>

            <Paragraph>
                Paciente maior de 18 anos orientar sobre o direito de denúncia e de realização do exame no instituto médico legal (Não obrigatório)
            </Paragraph>

            <Paragraph>
                Orientar a paciente sobre o direito a realizar o boletim de ocorrência policial e exame de corpo de delito pelo médico legista (Não obrigatório)
            </Paragraph>

            <Paragraph>
                Acolhimento, anamnese e escuta qualificada (médico e/ou enfermeiro(a))
            </Paragraph>

            <Paragraph>
                Garantir privacidade e local acolhedor
            </Paragraph>

            <Paragraph>
                NOTIFICAÇÃO COMPULSÓRIA
            </Paragraph>
            
        </Container>
    )
}