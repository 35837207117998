import React from 'react'

import { Container } from './styles'

import Title from '../../Components/Title'
import Button from '../../Components/Button'

import { useHistory } from 'react-router-dom'

export default function RiscosPage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Avaliação de Riscos</Title>

            <Button onClick={() => {
                history.push("violenciaCronica")
            }}>Violência Sexual Crônica</Button>

            <Button onClick={() => {
                history.push("violenciaRecente")
            }}>Violência Sexual Recente</Button>

            <Button onClick={() => {
                history.push("gravidez")
            }}>Gravidez decorrente da violência</Button>
            
        </Container>
    )
}