import React from 'react'

import { Container, Text } from './styles'

import Title from '../../Components/Title'
import Button from '../../Components/Button'
import { useHistory } from 'react-router-dom'

export default function InitialPage (){
    const history = useHistory()
    return (
        <Container>
            <Title>Introdução</Title>
            <Text>Esse aplicativo se destina à orientação dos profissionais da saúde na prevenção e tratamento dos agravos resultantes da violência sexual contra mulheres, tendo como fundamento o fornecimento de uma tecnologia móvel e acessível que auxilie durante a prática do atendimento a essas vítimas, levando a uma conduta mais ágil e precisa desses casos.</Text>
            <Button onClick={() => {
                history.push("mainMenu")
            }}>Avançar</Button>
        </Container>
    )
}