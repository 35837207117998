import styled from 'styled-components'

export const Container = styled.header`
    background-color: #EFEFEF;
    padding: 30px 0; 
    text-align: center;  
    border-bottom: 10px solid #D1D1D1;
`

export const Image = styled.img`
    max-width: 60%;
`