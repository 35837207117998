import styled from 'styled-components'

export const Container = styled.section`
    border-top: 10px solid red;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-around;
`

export const Image = styled.img`
    max-width: 50%;
`

export const Text = styled.p`
    margin: 30px;
`