import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`

export const Paragraph = styled.p`
    padding: 10px 20px;
    width: 100%;
`

export const TextBold = styled.span`
    font-weight: bold;
`

export const TextItalic = styled.i``