import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function SifilisSeguimentoLabPage(){
    return (
        <Container>
            <Title>Seguimento Laboratorial</Title>

            <Paragraph>
                Solicitar VDRL na admissão, 6 semanas, 3 e 6 meses
            </Paragraph>
        </Container>
    )
}