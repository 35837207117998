import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function AbortoLegal20Page(){
    return (
        <Container>
            <Title>Mais de 20 semanas</Title>

            <Paragraph>
                Encaminhar a gestante para seguimento em pré-natal de alto risco, pois a interrupção da gravidez está contra indicada
            </Paragraph>

            <Paragraph>
                Obs.: A gestante pode requerer a interrupção da gravidez após 20 semanas mediante ordem judicial. Por isso, acima dessa idade gestacional deve ser encaminhada ao serviço de pré-natal de alto risco para receber orientações quanto à interrupção da gravidez ou seu seguimento, com aceitação do bebê ou sua entrega à adoção, após seu nascimento.
            </Paragraph>
        </Container>
    )
}