import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function HepatitePepPage(){
    return (
        <Container>
            <Title>Profilaxia Pós-Exposição</Title>

            <Paragraph>
                Realizada após qualquer tipo de violência sexual
            </Paragraph>

            <SubTitle>
                PEP recomendada
            </SubTitle>

            <Paragraph>
                Paciente sem história vacinal ou esquema incompleto devem receber a vacina e a imunoglobulina anti-hepatite B
            </Paragraph>

            <Paragraph>
                Esquema vacinal com três doses completas (0, 1 e 6 meses) aplicadas por via IM em deltóide
            </Paragraph>

            <Paragraph>
                Imunoglobulina humana anti-hepatite B: 0,06 ml/kg IM no glúteo profundo em dose única em até 28 dias após a agressão
            </Paragraph>

            <SubTitle>
                PEP não recomendada
            </SubTitle>

            <Paragraph>
                Paciente vacinada com esquema completo
            </Paragraph>
        </Container>
    )
}