import React from 'react'

import Header from '../Header'

export default function ContainerPage({ children }){
    return (
        <>
            <Header />
            { children }
        </>
    )
}