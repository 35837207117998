import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function AbortoLegal1320Page(){
    return (
        <Container>
            <Title>De 13 a 20 semanas</Title>

            <Paragraph>
            Misoprostol 200 a 400 mcg via vaginal a cada 6 horas seguido de curetagem uterina da placenta após eliminação do concepto (AMIU está contra indicada)
            </Paragraph>
        </Container>
    )
}