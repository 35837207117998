import React from 'react'

import { Container, Paragraph, TextItalic } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function InfeccoesGenitaisInformacoesPage(){
    return (
        <Container>
            <Title>Informações</Title>

            <SubTitle>
                Agente causador
            </SubTitle>

            <Paragraph>
                <TextItalic>Clamydia tracomatis (Clamidiose), Haemophilus ducreyi (Cancro mole), Neisseria gonorrhoeae (Gonorréia) e Trichomonas vaginallis (Tricomoníase)</TextItalic>
            </Paragraph>

            <SubTitle>Transmissão</SubTitle>
            
            <Paragraph>
                Relação sexual desprotegida
            </Paragraph>

            <SubTitle>
                Diagnóstico
            </SubTitle>

            <Paragraph>
                Teste rápido (Clamidiose e gonorréia), exame clínico laboratorial (Cancro-mole) e exame a fresco do conteúdo vaginal (Tricomoníase e vaginose bacteriana)
            </Paragraph>
        </Container>
    )
}