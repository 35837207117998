import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function ContracepcaoEmergencialPrimeiraPage(){
    return (
        <Container>
            <Title>Primeira Escolha</Title>

            <Paragraph>
                Levonorgestrel 150 mcg ou 1,5mg (Apresentação: cartela com 2 comprimidos de 75mcg ou 0,75mg e cartela com 1 compimido de 150mcg ou 1,5mg)
            </Paragraph>

            <SubTitle>1a. opção</SubTitle>

            <Paragraph>
                1 comprimido de 1,5mg ou 2 comprimidos de 0,75mg, em dose única, até 5 dias após o abuso sexual
            </Paragraph>

            <SubTitle>2a. opção</SubTitle>

            <Paragraph>
                1 comprimido de 0,75mg, a cada 12 horas, totalizando 2 comprimidos ao dia, em dose única, até 5 dias após o abuso sexual
            </Paragraph>

        </Container>
    )
}