import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'

export default function AbortoLegalInformacoesPage(){
    return (
        <Container>
            <Title>Informações</Title>

            <Paragraph>
                Paciente deve ser prontamente atendida ou encaminhada ao serviço de referência
            </Paragraph>

            <Paragraph>
                Assinar o Termo de Consentimento Livre e Esclarecido a respeito do aborto legal
            </Paragraph>

            <Paragraph>
                Colher tipagem sanguínea e proceder Imunoglubulina anti-D(Rh) em até 72 horas após o aborto (se a paciente for Rh negativa)
            </Paragraph>
        </Container>
    )
}