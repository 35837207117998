import React from 'react'

import { Container, Paragraph } from './styles'

import { useHistory } from 'react-router-dom'
import Button from '../../Components/Button'

export default function ClinicoLaboratorialPage(){
    const history = useHistory()
    return (
        <Container>

            <Paragraph>
                Seguimento clínico-laboratorial e acompanhamento psicoemocional
            </Paragraph>

            <Paragraph>
                Encaminhamento para a rede de apoio intersetorial (Equipe multiprofissional)
            </Paragraph>

            <Button onClick={() => {
                history.push("altaHospitalar")
            }}>Próximo</Button>
        </Container>
    )
}