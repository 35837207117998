import React from 'react'

import { Container, Paragraph } from './styles'
import Title from '../../Components/Title'

export default function HivSeguimentoLabPage(){
    return (
        <Container>
            <Title>Seguimento Laboratorial</Title>

            <Paragraph>
                Teste anti-HIV: feito na admissão, 6 semanas, 3 meses e 6 meses
            </Paragraph>
            <Paragraph>
                Deve ser feito para vítimas com sinais agudos de infecção pelo HIV independente do tempo pós-exposição
            </Paragraph>
            <Paragraph>
                Dosagem de transaminases e hemograma, feitos na admissão e em 2 semanas para os usuários de anti-retrovirais
            </Paragraph>
        </Container>
    )
}