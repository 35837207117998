import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'
import Button from '../../Components/Button'

import { useHistory } from 'react-router-dom'

export default function ProcedimentosPage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Procedimentos</Title>

            <Paragraph>
                Realização de exame ginecológico, exame a fresco do conteúdo vaginal, testes rápidos e exames laboratoriais
            </Paragraph>

            <Paragraph>
                Realizar exame físico completo com descrição de todas as lesões encontradas
            </Paragraph>

            <Paragraph>
                Coleta de material em papel filtro. Anexar o papel em envelope pardo no prontuário (Não usar envelope plástico, pois desnatura o DNA)
            </Paragraph>

            <Button onClick={() => {
                history.push("riscos")
            }}>Avaliação de Riscos</Button>
            
        </Container>
    )
}