import styled from 'styled-components'

export const Container = styled.div`
    margin: 30px;
    padding: 15px;
    width: 80%;
    background: #006600;
    text-transform: uppercase;
    border-radius: 50px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
`

