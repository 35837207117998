import React from 'react'
import { useHistory } from 'react-router-dom'

import { Container } from './styles'

import Title from '../../Components/Title'
import Button from '../../Components/Button'

export default function ContracepcaoEmergencialPage(){
    const history = useHistory()
    return (
        <Container>
            <Title>Contracepção Emergencial</Title>

            <Button onClick={() => {
                history.push("contracepcaoEmergencial/informacoes")
            }}>Informações</Button>
            <Button onClick={() => {
                history.push("contracepcaoEmergencial/primeiraEscolha")
            }}>Primeira Escolha</Button>
            <Button onClick={() => {
                history.push("contracepcaoEmergencial/segundaEscolha")
            }}>Segunda Escolha</Button>
            <Button onClick={() => {
                history.push("contracepcaoEmergencial/contraIndicacao")
            }}>Contra Indicação</Button>
        </Container>
    )
}