import React from 'react'

import { Container, Paragraph } from './styles'

import Title from '../../Components/Title'
import SubTitle from '../../Components/SubTitle'

export default function HepatiteInformacoesPage(){
    return (
        <Container>
            <Title>Informações</Title>

            <SubTitle>Agente causador</SubTitle>

            <Paragraph>
                Vírus da Hepatite B
            </Paragraph>

            <SubTitle>Transmissão</SubTitle> 

            <Paragraph>
                Relação sexual desprotegida, compartilhamento de seringas contaminadas, contato com sangue contaminado, transmissão vertical e contato íntimo domiciliar (compartilhamento de escova dental e lâmina de barbear)
            </Paragraph>

            <SubTitle>Diagnóstico:</SubTitle>

            <Paragraph>
                Teste rápido (30 minutos) e Exames laboratoriais (Testes sorológicos)
            </Paragraph>
        </Container>
    )
}