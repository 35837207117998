import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    justify-content: space-around;
`

export const Text = styled.p`
    margin: 10px;
    
`